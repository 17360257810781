<template>
  <div>
    <div class="logo">
      <a href="">
        <img
          src="../../assets/images/register_logo.png"
          alt=""
        />
        <p>一站式工业仪器仪表交易平台-买卖仪器仪表就上仪商城</p>
      </a>
    </div>
    <div class="main">
      <div class="con">
        <div class="main_register">
          <div class="mine">
            <h2 style="margin-bottom: 25px">重置密码</h2>

            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="0"
              class="demo-ruleForm"
              style="border: none"
            >
              <el-form-item
                prop="mobile"
                style="margin-bottom: 25px"
              >
                <el-input
                  type="text"
                  v-model="ruleForm.mobile"
                  placeholder="手机号"
                />
              </el-form-item>
              <el-form-item
                style="margin-bottom: 25px"
                prop="code"
              >
                <el-col :span="16">
                  <el-input
                    style="width: 100%；"
                    v-model="ruleForm.code"
                    placeholder="验证码"
                    :clearBtn="true"
                    :disabled="false"
                  />
                </el-col>
                <el-col :span="8">
                  <el-button
                    type="danger"
                    style="
                      padding: 0 0.1rem;
                      width: 100%;
                      height: 40px;
                      line-height:40px;
                      
                      float: right;
                    "
                    @click="getCode()"
                    :disabled="yzmDisabled"
                  >
                    {{ codeInput }}
                  </el-button>
                </el-col>
              </el-form-item>
              <el-form-item
                prop="password1"
                style="margin-bottom: 25px"
              >
                <el-input
                  type="password"
                  v-model="ruleForm.password1"
                  placeholder="密码（须带字母的6位数）"
                />
              </el-form-item>
              <el-form-item
                prop="password2"
                style="margin-bottom: 25px"
              >
                <el-input
                  type="password"
                  v-model="ruleForm.password2"
                  placeholder="确认密码"
                />
              </el-form-item>
              <el-form-item style="margin-bottom: 25px">
                <el-button
                  type="primary"
                  @click="submit('ruleForm')"
                  ref="btn"
                >提交</el-button>
              </el-form-item>
            </el-form>
          </div>
          <!-- <div class="button">
            <h4>————— 其他登录方式 —————</h4>
            <a>
              <li><img
                  src="../../assets/imges/zhi.png"
                  alt=""
                ></li>
            </a>
            <a>
              <li><img
                  src="../../assets/imges/wx.png"
                  alt=""
                ></li>
            </a>
            <a>
              <li><img
                  src="../../assets/imges/weibo.png"
                  alt=""
                ></li>
            </a>
            <a>
              <li><img
                  src="../../assets/imges/weibo.png"
                  alt=""
                ></li>
            </a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import identity from "@/assets/utils/identity";

export default {
  data() {
    return {
      show: true,
      index: "1",
      account: "",

      codeInput: "发送验证码",

      yzmDisabled: false,
      checkbox1: false,
      time: 2,

      ruleForm: {
        mobile: "",
        code: "",
        password1: "",
        password2: ""
      },
      rules: {
        mobile: [
          { required: true, message: "请输入手机", trigger: "change" },
          {
            pattern: this.$validate.phone,
            message: "请输入正确的手机",
            trigger: "change"
          }
        ],
        code: [{ required: true, message: "请输入验证", trigger: "change" }],
        password1: [
          { required: true, message: "请输入密码", trigger: "change" },
          {
            pattern: this.$validate.password,
            message: "密码必须带字母的6位数",
            trigger: "change"
          }
        ],
        password2: [
          { required: true, message: "请再次输入密码", trigger: "change" }
        ]
      }
    };
  },
  created() {},
  methods: {
    //请求验证码
    getCode() {
      this.$post("home/user/forgotFindMobile", {
        mobile: this.ruleForm.mobile
      }).then(res => {
        //检查手机号是否存在
        if (res.code == 20000) {
          this.$message.warn("电话未注册！");
          return;
        }
        this.$post("home/user/code", { mobile: this.ruleForm.mobile }).then(
          res => {
            console.log("code", res);

            this.time = 2;
            this.timer();
          }
        );
      });
    },
    //1分钟倒计时
    timer() {
      if (this.time > 0) {
        this.yzmDisabled = true;
        this.time--;
        this.codeInput = this.time + "秒";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.codeInput = "发送验证码";
        this.yzmDisabled = false;
      }
    },

    //个人注册
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.ruleForm.password1 != this.ruleForm.password2) {
            this.$message("密码不一致");
            return;
          }
          //提交
          this.$post("home/user/getpwd", {
            mobile: this.ruleForm.mobile,
            password: this.ruleForm.password1,
            code: this.ruleForm.code
          }).then(res => {
            this.$message.success("修改成功！");

            this.$router.push({
              path: "/login"
            });
          });
        } else {
          // console.log("error submit!!")
          return false;
        }
      });
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/base";
// color: #D64848;
::v-deep .is-active {
  color: #e74c26;
}
.logo {
  width: 1226px;
  box-sizing: border-box;
  margin: 0px auto;
  height: 36px;
  padding: 20px 0 70px;
  img {
    width: 150px;
    height: 50px;
    float: left;
  }
  p {
    float: left;
    line-height: 72px;
    margin-left: 10px;
    color: #000;
    font-size: 25px;
    font-family: "KaiTi", Georgia, Serif;
  }
}
.main {
  background: url(../../assets/images/register_main.jpg) center 0px / 100% 802px
    no-repeat;
  width: 100%;
  height: 800px;
  overflow: hidden;
  margin: 0 auto;
  .con {
    width: 1226px;
    box-sizing: border-box;
    margin: 0 auto;
    .main_register {
      width: 350px;
      height: 450px;
      background: #fff;
      float: right;
      margin: 100px 200px 0 0;
      position: relative;
      .mine {
        margin: 20px;

        h2 {
          font-size: 16px;
        }
        p {
          width: 100%;
          height: 30px;
          line-height: 30px;
          background-color: #fef2f2;
          border-color: #ffb4a8;
          border: 1px solid #ff934c;
          i {
            color: #ce201e;
            margin-left: 5px;
          }
        }
        input {
          width: 100%;
          height: 45px;

          text-indent: 10px;
        }
        button {
          display: block;
          width: 100%;
          height: 45px;

          color: #fff;
          cursor: pointer;
          font-size: 17px;
          // position:absolute;
          // top:50%;
          // left:50%;
          // transform:translate(-50% -50%);
          // font-family:"KaiTi",Georgia,Serif;
          // overflow:hidden;
        }
        a {
          color: red;
          font-size: 12px;
          margin-right: 25px;
        }
      }
      .button {
        position: absolute;
        bottom: 30px;
        left: 62.5px;
        font-size: 16px;
        h4 {
          text-align: center;
        }
        a {
          float: left;
          margin-top: 30px;
        }
        :nth-child(n + 2) {
          margin-right: 0;
          margin-left: 25px;
        }
        .diudiu {
          position: absolute;
          top: 5%;
          left: 5%;
        }
      }
    }
  }
}
</style>
